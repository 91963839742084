import React, { useState, useEffect } from "react"
import imgOne from "@components/pagePartners/images/hexagonoamarillo.png"
import imgTwo from "@components/pagePartners/images/hexagonoazul.png"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"
import { Link } from "gatsby"
import Select from "react-select"
import TitleUnderline from "./titleUnderline"


const SliderItem = ({ data, onClickConfirm }) => {
  const {
    start,
    countries,
    numberCollaborators,
    markets,
    services,
    products,
    experiences,
    companiesClients,
  } = data

  //Estado valores inputs
  const [values, setValues] = useState({
    name: "",
    lastname: "",
    email: "",
    company: "",
    pais: "default",
    collaborators: "default",
    description: "",
    codeCountry: {
      value: "Perú",
      label: "Perú",
      image: "https://flagcdn.com/w320/pe.png",
      code: "+51",
    },
    phone: "",
    website: "",
    typePartner: "",
    market: "default",
    service: "default",
    companiesClient: "default",
    product: "default",
    experience: "default",
  })

  const [blogChecked, setBlogChecked] = useState(false)

  //Estado validaciones
  const [validations, setValidations] = useState({
    name: "",
    lastname: "",
    email: "",
    company: "",
    pais: "",
    collaborators: "",
    description: "",
    codeCountry: "",
    phone: "",
    website: "",
    typePartner: "",
    market: "",
    service: "",
    companiesClient: "",
    product: "",
    experience: "",
  })

  //Validar todos los campos que no esten vacios y en el formato correcto al presionar enviar
  const validateAll = () => {
    const Blacklist = [
      "@gmail.com",
      "@hotmail.com",
      "@yahoo.com",
      "@outlook.com",
      "yahoo.es",
      "yahoo.com.mx",
      "icloud.com",
      "gmail.co",
      "hotmail.es",
      "hotmail.it",
      "outlook.com",
      "outlook.es",
    ]

    const {
      name,
      lastname,
      email,
      pais,
      collaborators,
      description,
      codeCountry,
      phone,
      website,
      typePartner,
      market,
      service,
      companiesClient,
      product,
      experience,
    } = values

    const validations = {
      name: "",
      lastname: "",
      email: "",
      company: "",
      pais: "",
      collaborators: "",
      description: "",
      codeCountry: "",
      phone: "",
      website: "",
      typePartner: "",
      market: "",
      service: "",
      companiesClient: "",
      product: "",
      experience: "",
    }
    let isValid = true

    if (!name) {
      validations.name = "Campo requerido"
      isValid = false
    }
    if (!lastname) {
      validations.lastname = "Campo requerido"
      isValid = false
    }

    if (!email) {
      validations.email = "Campo requerido"
      isValid = false
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "El formato debe ser el example@mail.com"
      isValid = false
    }

    if (Blacklist) {
      for (let i = 0; i < Blacklist.length; i++)
        if (email.toLowerCase().includes(Blacklist[i]) === true) {
          validations.email = "Ingrese un correo corporativo"
          isValid = false
        }
    }

    if (!phone) {
      validations.phone = "Campo requerido"
      isValid = false
    }

    if (phone && !phone.match(/^[0-9]+$/)) {
      validations.phone = "Ingresar solo numeros"
      isValid = false
    }

    if (!company) {
      validations.company = "Campo requerido"
      isValid = false
    }

    if (pais === "default") {
      validations.pais = "Campo requerido"
      isValid = false
    }
    if (codeCountry === "default") {
      validations.codeCountry = "*"
      isValid = false
    }

    if (collaborators === "default") {
      validations.collaborators = "Campo requerido"
      isValid = false
    }
    if (website === "") {
      validations.website = "Campo requerido"
      isValid = false
    }
    if (description === "") {
      validations.description = "Campo requerido"
      isValid = false
    }
    if (typePartner === "") {
      validations.typePartner = "Campo requerido"
      isValid = false
    }
    if (market === "default") {
      validations.market = "Campo requerido"
      isValid = false
    }
    if (service === "default") {
      validations.service = "Campo requerido"
      isValid = false
    }
    if (companiesClient === "default") {
      validations.companiesClient = "Campo requerido"
      isValid = false
    }
    if (product === "default") {
      validations.product = "Campo requerido"
      isValid = false
    }
    if (experience === "default") {
      validations.experience = "Campo requerido"
      isValid = false
    }

    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  //Validar campos mientras el usuario escribe
  const validateOne = e => {
    const { name } = e.target
    const value = values[name]
    let message = ""

    if (!value) {
      message = `Campo requerido`
    }

    if (
      (name === "name" ||
        name === "lastname" ||
        name === "company" ) &&
      !/^[a-zA-Z\s]+$/.test(value)
    ) {
      message = "Ingresar solo letras"
    }

    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "El formato debe ser el example@mail.com"
    }

    if (value && name === "phone" && !/^[0-9]+$/.test(value)) {
      message = "Ingresar solo numeros"
    }

    setValidations({ ...validations, [name]: message })
  }

  //Obtener y guardar valores de los inputs
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const {
    name,
    lastname,
    email,
    company,
    pais,
    collaborators,
    description,
    phone,
    website,
    typePartner,
    market,
    service,
    companiesClient,
    product,
    experience,
  } = values

  const {
    name: nameVal,
    lastname: lastnameVal,
    email: emailVal,
    company: companyVal,
    pais: paisVal,
    collaborators: collaboratorsVal,
    description: descriptionVal,
    codeCountry: codeCountryVal,
    phone: phoneVal,
    website: websiteVal,
    typePartner: typePartnerVal,
    market: marketVal,
    service: serviceVal,
    companiesClient: companiesClientVal,
    product: productVal,
    experience: experienceVal,
  } = validations

  //Estado para mostrar el spin de cargando en boton enviar
  const [spin, setSpin] = useState(false)

  //Funcion envio formulario
  const handleSubmit = async e => {
    e.preventDefault()
    const isValid = validateAll()

    //Validar los campos
    if (isValid) {
      setSpin(true)

      const body = {
        event: "Formulario-Contacto-Partners",
      }
      if (window.dataLayer) {
        // para controlar si includeInDevelopment (gtm) es false
        window.dataLayer.push(body)
      }

      let success = true
      // Conexión con Backend
      try {
        const params = new URLSearchParams()
        params.append("typePartner", typePartner) // Valores: referral_partner sales_partner
        params.append("name", values.name)
        params.append("lastname", values.lastname)
        params.append("email", values.email)
        params.append("codeCountry", values.codeCountry.code)
        params.append("phone", values.phone)
        params.append("company", values.company)
        params.append("website", values.website)
        params.append("country", values.pais.label)
        params.append("workers", values.collaborators)
        params.append("industry", values.market)
        params.append("service", values.service)
        params.append("portfolio", values.companiesClient)
        params.append("gotProduct", values.product)
        params.append("partnerExperience", values.experience)
        params.append("description", values.description)
        params.append("blogChecked", blogChecked)
        params.append("type_action", "sendForm")

        await fetch(`/backend/partners.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
          },
          body: params,
        })
      } catch (error) {
        console.log(error)
        success = false
      }
      if (success) {
        setTimeout(() => {
          setSpin(false)
          onClickConfirm()
        }, 2000)
        setValues({
          name: "",
          lastname: "",
          email: "",
          company: "",
          pais: "default",
          collaborators: "default",
          description: "",
          codeCountry: "default",
          phone: "",
          website: "",
          market: "default",
          service: "default",
          companiesClient: "default",
          product: "default",
          experience: "default",
          typePartner: "",
        })
      }
    } else {
      return false
    }
  }

  const handleChangeCountry = value => {
    setValues({ ...values, codeCountry: value })
  }

  const customStyles = {
    option: provided => ({
      ...provided,
      width: 150,
      paddingTop: 10,
    }),
    menu: provided => ({
      ...provided,
      width: 150,
      marginTop: 0,
    }),
    control: provided => ({
      ...provided,
      borderRadius: 8,
      minHeight: 50,
      width: windowsWidth >= 350 ? "130px" : "100%",
      margin: windowsWidth >= 350 ? "10px 0px" : "10px 0px 0px",
    }),
  }

  const handleChangePais = value => {
    setValues({ ...values, pais: value })
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const [windowsWidth, setWindowsWidth] = useState(350)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 464) {
      setWindowsWidth(270)
    } else {
      setWindowsWidth(350)
    }
  }, [])

  const customStylesPais = {
    option: provided => ({
      ...provided,
      width: windowsWidth,
      paddingTop: 10,
    }),
    menu: provided => ({
      ...provided,
      width: windowsWidth,
      marginTop: 0,
    }),
    placeholder: provided => ({
      ...provided,
      color: "#c7c7c7",
      marginLeft: 6,
      marginRight: 0,
      textAlign: "left",
    }),
    control: provided => ({
      ...provided,
      width: windowsWidth >= 350 ? "389px" : "100%",
      borderRadius: 8,
      margin: windowsWidth >= 350 ? "10px 0px 0" : "11px auto 4px",
      minHeight: 51,
    }),
  }

  return (
    <div className="container-component-form-partners container-form-partner">
      <div className="section-form-contact-whatsapp">
        <img loading="lazy" src={imgOne} alt="whatsapp" className="amarillo" />
        <section className="section-contacto">
          <div className="start-pricing-whatsapp">
            <section>
              <p className="container-form-partner-title">
                ¡Estás a un paso de convertirte en <TitleUnderline underline="nuestro partner" />! 💛
              </p>

              <p className="container-form-partner-text">{start.textbody}</p>
            </section>
          </div>
          <div className="container-benefit-logo-banner">
            <svg width="593" height="52" viewBox="0 0 593 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 26.0869H190.003" stroke="#9BBBF5" stroke-width="2" />
              <circle cx="318.503" cy="12.0869" r="11.5" fill="#F2ACA9" />
              <circle cx="357.003" cy="38.5869" r="6" fill="#66E28E" />
              <circle cx="300.003" cy="37.5869" r="7" fill="#88AEF3" />
              <path d="M277.003 28.0869C277.003 41.0656 266.482 51.5869 253.503 51.5869C240.524 51.5869 230.003 41.0656 230.003 28.0869C230.003 15.1082 240.524 4.58691 253.503 4.58691C266.482 4.58691 277.003 15.1082 277.003 28.0869ZM246.95 28.0869C246.95 31.706 249.884 34.6399 253.503 34.6399C257.122 34.6399 260.056 31.706 260.056 28.0869C260.056 24.4678 257.122 21.5339 253.503 21.5339C249.884 21.5339 246.95 24.4678 246.95 28.0869Z" fill="#FFC700" />
              <path d="M403.003 26.0869H593.006" stroke="#9BBBF5" stroke-width="2" />
            </svg>

          </div>
          <div className="container-heroebook-suscribirtwo">
            <div>
              <p className="container-form-partners-titles">
                Deseo postular para ser:
              </p>
              <form className="container-form-partners-type-partner">
                <div className="container-form-partners-type-partner-card">
                  <div className="">
                    <input
                      type="radio"
                      value="referral_partner"
                      onChange={handleChange}
                      name="typePartner"
                    />
                  </div>
                  <div>
                    <p className="container-form-partners-type-partner-card-title">
                      Partner de referidos{" "}
                    </p>
                    <p className="container-form-partners-type-partner-card-text">
                      Refiere o recomienda los productos de Beex a tus
                      actuales clientes potenciales.
                    </p>
                    <Link
                      to="/programa-partners-beex/#referidos"
                      className="container-form-partners-button"
                    >
                      Leer más
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z"
                          fill="#5831D8"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="container-form-partners-type-partner-card">
                  <div className="">
                    <input
                      type="radio"
                      value="sales_partner"
                      onChange={handleChange}
                      name="typePartner"
                    />
                  </div>
                  <div>
                    <p className="container-form-partners-type-partner-card-title">
                      Partner de ventas
                    </p>
                    <p className="container-form-partners-type-partner-card-text">
                      Realiza todo el proceso comercial de nuestros productos a
                      toda tu cartera de clientes.
                    </p>
                    <Link
                      to="/programa-partners-beex/#referidos"
                      className="container-form-partners-button"
                    >
                      Leer más
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z"
                          fill="#5831D8"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </form>
              <div>
                <div className="container-form-partners-type-partner-error">
                  <div className="error">{typePartnerVal}</div>
                </div>
              </div>
            </div>
            <p className="container-form-partners-titles">Tus datos:</p>
            <div className="inputs-form">
              <div>
                <input
                  className="input-hero"
                  placeholder="Nombres"
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  onBlur={validateOne}
                />
                <div className="error">{nameVal}</div>
              </div>

              <div>
                <input
                  className="input-hero"
                  placeholder="Apellidos"
                  type="text"
                  name="lastname"
                  value={lastname}
                  onChange={handleChange}
                  onBlur={validateOne}
                />
                <div className="error">{lastnameVal}</div>
              </div>
            </div>

            <div className="inputs-form">
              <div>
                <input
                  className="input-hero"
                  placeholder="Correo corporativo"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={validateOne}
                />
                <div className="error">{emailVal}</div>
              </div>
              <div className="code-phone">
                <div>
                  <Select
                    options={countries}
                    defaultValue={{
                      value: "Perú",
                      label: "Perú",
                      image: "https://flagcdn.com/w320/pe.png",
                      code: "+51",
                    }}
                    onChange={value => handleChangeCountry(value)}
                    placeholder={"Codigo"}
                    styles={customStyles}
                    formatOptionLabel={country => (
                      <div className="country-option">
                        <img src={country.image} alt="country-image" />
                        <span>{country.code}</span>
                      </div>
                    )}
                  />
                  <div className="error">{codeCountryVal}</div>
                </div>
                <div>
                  <input
                    className="input-hero"
                    placeholder="Telefono"
                    type="text"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    onBlur={validateOne}
                  />
                  <div className="error">{phoneVal}</div>
                </div>
              </div>
            </div>

            <p className="container-form-partners-titles">
              Datos de tu empresa:
            </p>
            <div className="inputs-form">
              <div>
                <input
                  className="input-hero"
                  placeholder="Nombre de la Empresa"
                  type="text"
                  name="company"
                  value={company}
                  onChange={handleChange}
                  onBlur={validateOne}
                />
                <div className="error">{companyVal}</div>
              </div>
              <div>
                <input
                  className="input-hero"
                  placeholder="Página web"
                  type="text"
                  name="website"
                  value={website}
                  onChange={handleChange}
                  onBlur={validateOne}
                />
                <div className="error">{websiteVal}</div>
              </div>
            </div>

            <div className="inputs-form">
              <div className="select-paises">
                <Select
                  options={countries}
                  value={pais}
                  onChange={value => handleChangePais(value)}
                  placeholder={" País de la empresa"}
                  styles={customStylesPais}
                  formatOptionLabel={country => (
                    <div className="country-option">
                      <img src={country.image} alt="country-image" />
                      <span>{country.label}</span>
                    </div>
                  )}
                />

                <div className="error">{paisVal}</div>
              </div>

              <div>
                <div style={{ position: "relative" }}>
                  <select
                    className={
                      collaborators === "default"
                        ? "input-hero select-default "
                        : "input-hero"
                    }
                    type="text"
                    name="collaborators"
                    value={collaborators}
                    onChange={handleChange}
                    onBlur={validateOne}
                    defaultValue={collaborators}
                  >
                    <option value="default" disabled hidden>
                      Número de colaboradores
                    </option>
                    {numberCollaborators.map((element, index) => {
                      return (
                        <option key={index} value={element}>
                          {element}
                        </option>
                      )
                    })}
                  </select>
                  <i></i>
                </div>
                <div className="error">{collaboratorsVal}</div>
              </div>
            </div>

            <div className="inputs-form">
              <div>
                <div style={{ position: "relative" }}>
                  <select
                    className={
                      market === "default"
                        ? "input-hero select-default "
                        : "input-hero"
                    }
                    type="text"
                    name="market"
                    value={market}
                    onChange={handleChange}
                    onBlur={validateOne}
                    defaultValue={market}
                  >
                    <option value="default" disabled hidden>
                      Mercado donde ofreces tus servicios
                    </option>
                    {markets.map((element, index) => {
                      return (
                        <option key={index} value={element.pais}>
                          {element}
                        </option>
                      )
                    })}
                  </select>
                  <i></i>
                </div>
                <div className="error">{marketVal}</div>
              </div>

              <div>
                <div style={{ position: "relative" }}>
                  <select
                    className={
                      service === "default"
                        ? "input-hero select-default "
                        : "input-hero"
                    }
                    type="text"
                    name="service"
                    value={service}
                    onChange={handleChange}
                    onBlur={validateOne}
                    defaultValue={service}
                  >
                    <option value="default" disabled hidden>
                      ¿Qué servicio ofreces?
                    </option>
                    {services.map((element, index) => {
                      return (
                        <option key={index} value={element}>
                          {element}
                        </option>
                      )
                    })}
                  </select>
                  <i></i>
                </div>
                <div className="error">{serviceVal}</div>
              </div>
            </div>

            <div className="inputs-form">
              <div>
                <div style={{ position: "relative" }}>
                  <select
                    className={
                      companiesClient === "default"
                        ? "input-hero select-default "
                        : "input-hero"
                    }
                    type="text"
                    name="companiesClient"
                    value={companiesClient}
                    onChange={handleChange}
                    onBlur={validateOne}
                    defaultValue={companiesClient}
                  >
                    <option value="default" disabled hidden>
                      # Empresas de tu cartera de clientes potenciales
                    </option>
                    {companiesClients.map((element, index) => {
                      return (
                        <option key={index} value={element.pais}>
                          {element}
                        </option>
                      )
                    })}
                  </select>
                  <i></i>
                </div>
                <div className="error">{companiesClientVal}</div>
              </div>

              <div>
                <div style={{ position: "relative" }}>
                  <select
                    className={
                      product === "default"
                        ? "input-hero select-default "
                        : "input-hero"
                    }
                    type="text"
                    name="product"
                    value={product}
                    onChange={handleChange}
                    onBlur={validateOne}
                    defaultValue={product}
                  >
                    <option value="default" disabled hidden>
                      ¿Cuentas con algún producto de Beex?
                    </option>
                    {products.map((element, index) => {
                      return (
                        <option key={index} value={element}>
                          {element}
                        </option>
                      )
                    })}
                  </select>
                  <i></i>
                </div>
                <div className="error">{productVal}</div>
              </div>
            </div>

            <div className="inputs-form input-total-campo">
              <div>
                <div style={{ position: "relative" }}>
                  <select
                    className={
                      experience === "default"
                        ? "input-hero select-default "
                        : "input-hero"
                    }
                    type="text"
                    name="experience"
                    value={experience}
                    onChange={handleChange}
                    onBlur={validateOne}
                    defaultValue={experience}
                  >
                    <option value="default" disabled hidden>
                      ¿Tienes experiencia actual como partner?
                    </option>
                    {experiences.map((element, index) => {
                      return (
                        <option key={index} value={element.pais}>
                          {element}
                        </option>
                      )
                    })}
                  </select>
                  <i></i>
                </div>
                <div className="error">{experienceVal}</div>
              </div>
            </div>

            <div className="inputs-form">
              <div>
                <textarea
                  className="textarea-form-whatsapp"
                  placeholder="¿Qué buscas con nuestro programa de partners?"
                  type="text"
                  name="description"
                  value={description}
                  onChange={handleChange}
                  onBlur={validateOne}
                  defaultValue={description}
                />
                <div className="error">{descriptionVal}</div>
              </div>
            </div>
            <div className="container-form-partners-checkox-demo">
              <input
                type="checkbox"
                value={blogChecked}
                onChange={() => setBlogChecked(!blogChecked)}
                defaultChecked={blogChecked}
              />
              <p>Deseo recibir artículos del blog de Beex 😍</p>
            </div>

            <div className="container-form-partners-button-summit">
              <button
                className="btn-start-pricing"
                onClick={handleSubmit}
                style={{ display: " inline-flex", gap: "12px" }}
              >
                Postular
                {spin ? (
                  <Loader type="Oval" color="#cbcbcb" height={20} width={20} />
                ) : (
                  ""
                )}
              </button>
            </div>
            <p className="link-ebook">{start.link}</p>
          </div>
        </section>
        <img loading="lazy" src={imgTwo} alt="hexagono" className="azul" />
      </div>
    </div>
  )
}

export default SliderItem
